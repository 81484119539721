export enum Routes {
  // Login
  LOGIN = '/login',
  LOGIN_ERROR = '/login-error',

  // Answer
  ANSWER = '/answer',

  // Settings
  SETTINGS = '/settings',
  SETTINGS_PERSONAL = '/settings/personal',
  SETTINGS_TEAM = '/settings/team',
  SETTINGS_ASSISTANTS = '/settings/assistants',
}
